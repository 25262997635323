import httpServices from 'services/http.service';
import { API_URLS } from 'constants/index';

export const upsertCart = (payload: { product: string, amount: number, originPrice: number }[]) => (
  httpServices.post(API_URLS.USER.SET_CART, { cart: payload })
);

export const deleteAccount = () => (
  httpServices.post(API_URLS.USER.DELETE_ACCOUNT)
);

export const userCurrency = (currency: string) => httpServices.post(API_URLS.USER.CURRENCY, { currency });

export const userLanguage = (lang: string) => httpServices.post(API_URLS.USER.LANGUAGE, { lang });

export const cartCurrency = (payload: { cart: Product.CartItem, currency: string }) => (
  httpServices.post(API_URLS.USER.CART_CURRENCY, payload)
);

export const incrementReferrer = (referrer: string) => httpServices.post(API_URLS.USER.REFERRER, { referrer });
