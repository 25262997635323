import {
  useCallback, useContext, useEffect, useRef,
} from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faXmark, faCircleCheck, faCircleXmark, faCircleExclamation, IconDefinition,
} from '@fortawesome/free-solid-svg-icons';

import { NoticeContext } from 'contexts/notification';

import styles from './index.module.scss';

const delay = 3000;

export type MessageType = 'success' | 'error' | 'warn' | 'info';

export interface DefaultMessageProps {
  message: string,
  id: number,
  type: MessageType;
}

const iconTypesMap: Record<string, IconDefinition> = {
  warn: faCircleExclamation,
  success: faCircleCheck,
  error: faCircleXmark,
  info: faCircleXmark,
};

const DefaultMessage = ({ message, id, type }: DefaultMessageProps) => {
  const { destroy } = useContext(NoticeContext);
  const timeout = useRef<NodeJS.Timeout | null>(null);

  const destroyAlarm = useCallback(() => {
    timeout.current = setTimeout(() => destroy(id), delay);
  }, [destroy, id]);

  useEffect(() => {
    destroyAlarm();
    return () => {
      if (timeout.current) clearTimeout(timeout.current);
    };
  }, [destroyAlarm]);

  return (
    <div className={classNames(styles.defaultMessage, { [styles[type]]: type })}>
      <div className={classNames(styles.messageWrapper, { [styles[type]]: type })}>
        <FontAwesomeIcon className={styles.icon} icon={iconTypesMap[type]} />
        <div className={styles.message}>{message}</div>
      </div>
      <FontAwesomeIcon className={styles.close} onClick={() => destroy(id)} icon={faXmark} />
    </div>
  );
};

export default DefaultMessage;
