import { createContext, ReactNode, useCallback, useMemo, useState, useRef } from 'react';
import DefaultMessage, { MessageType } from '../components/common/DefaultMessage';

export interface NoticeContextProps {
  message: (message: string | ReactNode, type?: MessageType) => void,
  destroy: (index: number) => void,
}

export interface NoticeItem {
  node: ReactNode;
  id: number;
}

export const NoticeContext = createContext<NoticeContextProps>({
  destroy: () => null,
  message: () => null,
});

export const NoticeContextProvider = ({ children }: { children: ReactNode }) => {
  const { current: noticeList } = useRef<NoticeItem[]>([]);
  const [elements, setElements] = useState<ReactNode>();
  const ref = useRef(0);

  const appendNoticeList = useCallback((item: NoticeItem) => {
    noticeList.push(item);
  }, [noticeList]);

  const renderMessage = useCallback(({ node, id }: NoticeItem) => (
    <div key={id.toString()}>
      {node}
    </div>
  ), []);

  const render = useCallback(() => {
    const updater = (
      <div className='notice-list-container'>
        {noticeList.map(renderMessage)}
      </div>
    );
    setElements(updater);
  }, [noticeList, renderMessage]);

  const message = useCallback((incomeMessage: string | ReactNode, type?: MessageType) => {
    const item = {
      node: typeof incomeMessage === 'string'
        ? <DefaultMessage message={incomeMessage} id={ref.current + 1} type={type || 'info'} />
        : incomeMessage,
      id: ref.current + 1,
    };
    appendNoticeList(item);
    ref.current += 1;
    render();
  }, [appendNoticeList, render]);

  const destroy = useCallback((id: number) => {
    const index = noticeList.findIndex((item) => item.id === id);
    noticeList.splice(index, 1);
    render();
  }, [noticeList, render]);

  const values = useMemo(() => ({
    message,
    destroy,
  }), [message, destroy]);

  return (
    <NoticeContext.Provider value={values}>
      {elements}
      {children}
    </NoticeContext.Provider>
  );
};
