import {
  createContext, ReactNode, useCallback, useMemo, useState,
} from 'react';
import { CURRENCY, ECurrency } from '../constants';

export interface AppContextProps {
  upsertUser: (user?: User) => void,
  user: User | undefined,
  updateCurrency: (val: ECurrency) => void,
  currency: ECurrency,
}

export const AppContext = createContext<AppContextProps>({
  upsertUser: () => null,
  user: undefined,
  updateCurrency: () => null,
  currency: CURRENCY.USD.val,
});

export const AppContextProvider = ({ children }: { children: ReactNode }) => {
  const [currency, setCurrency] = useState<ECurrency>(CURRENCY.USD.val);
  const [user, setUser] = useState<User>();
  const upsertUser = useCallback((u?: User) => {
    setUser(u);
    if (u) setCurrency(u?.currency);
  }, []);

  const updateCurrency = useCallback((val: ECurrency) => {
    setCurrency(val);
  }, []);

  const values = useMemo(() => ({
    upsertUser,
    user,
    updateCurrency,
    currency,
  }), [currency, updateCurrency, upsertUser, user]);

  return (
    <AppContext.Provider value={values}>
      {children}
    </AppContext.Provider>
  );
};
