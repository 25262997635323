import {
  createContext, Dispatch, ReactNode, SetStateAction, useCallback, useMemo, useState,
} from 'react';
import { useMutation } from 'react-query';

import { upsertCart } from 'services/user.service';
import { CART, CART_ACTIONS_TYPES } from 'constants/cart';
import { setStorage } from 'services/localStorage.service';
import { MaxCartAmountPerItem } from 'config/index';
import CartItem = Product.CartItem;

const totalPriceFunc = (cart: Product.CartItem[]) => cart
  .reduce((
    accumulator,
    currentValue,
  ) => accumulator + currentValue.amount * (currentValue.discountPrice || +currentValue.price.value), 0);

const totalAmountFunc = (cart: Product.CartItem[]) => cart
  .reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0);

export interface AppContextProps {
  cart: Product.CartItem[],
  totalAmount: number,
  totalPrice: number,
  updateItem: (item: Product.Item, amount?: number, type?: string) => void,
  removeItems: (item?: Product.Item) => void,
  setCart: Dispatch<SetStateAction<CartItem[]>>,
  updateCartItems: (items: CartItem[]) => void,
}

export const CartContext = createContext<AppContextProps>({
  cart: [],
  totalPrice: 0,
  totalAmount: 0,
  updateItem: () => null,
  removeItems: () => null,
  setCart: () => null,
  updateCartItems: () => null,
});

export const CartContextProvider = ({ children }: { children: ReactNode }) => {
  const [cart, setCart] = useState<Product.CartItem[]>([]);
  const { mutateAsync: updateCart } = useMutation(upsertCart);

  const onRemoteCartUpdate = useCallback((updater: Product.CartItem[]) => {
    updateCart(updater.map((u) => ({
      originPrice: u.originPrice,
      amount: u.amount,
      region: u.region,
      product: u.id,
    }))).catch(() => null);
  }, [updateCart]);

  const ApplyMaxAmount = useCallback((amount: number, item?: Product.CartItem, type?: string) => {
    if (amount > MaxCartAmountPerItem) return MaxCartAmountPerItem;
    if (amount < MaxCartAmountPerItem) return amount;
    if (item && type === CART_ACTIONS_TYPES.ADD && ((item.amount + amount) <= MaxCartAmountPerItem)) return item.amount + amount;
    if (item && ((type === CART_ACTIONS_TYPES.ADD && ((amount + item.amount) > MaxCartAmountPerItem))
      || item.amount >= MaxCartAmountPerItem || amount > MaxCartAmountPerItem || ((amount + item.amount) > MaxCartAmountPerItem))
    ) return MaxCartAmountPerItem;
    return amount;
  }, []);

  const updateItem = useCallback((item: Product.Item, incomeAmount = 1, type?: string) => {
    let updater = [...cart];
    const selectedItem = cart.findIndex((i) => `${i.originPrice}${i.id}` === `${item.originPrice}${item.id}`);
    const amount = ApplyMaxAmount(incomeAmount, updater[selectedItem], type);

    if (selectedItem === -1) updater.push({ ...item, amount });
    else if (amount === 0) {
      updater = updater.filter((u) => `${u.originPrice}${u.id}` !== `${item?.originPrice}${item?.id}`);
    } else if (type === CART_ACTIONS_TYPES.ADD) {
      updater[selectedItem] = {
        ...updater[selectedItem],
        amount: ApplyMaxAmount(updater[selectedItem].amount + amount, updater[selectedItem], type),
      };
    } else if (type === CART_ACTIONS_TYPES.UPDATE) {
      updater[selectedItem] = { ...updater[selectedItem],
        amount: ApplyMaxAmount(updater[selectedItem].amount + amount, updater[selectedItem], type),
      };
    } else updater[selectedItem] = { ...updater[selectedItem], amount };
    setStorage(CART, updater);
    setCart(updater);
    onRemoteCartUpdate(updater);
  }, [ApplyMaxAmount, cart, onRemoteCartUpdate]);

  const removeItems = useCallback((item?: Product.Item) => {
    let updater = [...cart];
    if (item) {
      const selectedItem = cart.findIndex((i) => `${i.originPrice}${i.id}` === `${item?.originPrice}${item?.id}`);
      if (selectedItem !== -1) {
        updater = updater.filter((u) => `${u.originPrice}${u.id}` !== `${item?.originPrice}${item?.id}`);
      }
    } else updater = [];
    setCart(updater);
    setStorage(CART, updater);
    onRemoteCartUpdate(updater);
  }, [cart, onRemoteCartUpdate]);

  const updateCartItems = useCallback((updater: CartItem[]) => {
    setCart(updater);
    setStorage(CART, updater);
  }, []);

  const values = useMemo(() => {
    const totalAmount = totalAmountFunc(cart);
    const totalPrice = totalPriceFunc(cart);
    return {
      cart,
      totalAmount,
      totalPrice,
      updateItem,
      removeItems,
      updateCartItems,
      setCart,
    };
  }, [cart, updateItem, removeItems, updateCartItems]);

  return (
    <CartContext.Provider value={values}>
      {children}
    </CartContext.Provider>
  );
};
