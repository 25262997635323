import { QueryClient, QueryClientProvider } from 'react-query';
import type { AppProps } from 'next/app';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import 'styles/globals.scss';
import { appWithTranslation } from 'next-i18next';
import { NoticeContextProvider } from 'contexts/notification';
import { AppContextProvider } from 'contexts';
import { CartContextProvider } from 'contexts/cartContext';

const queryClient = new QueryClient();

const MyApp = ({ Component, pageProps }: AppProps) => (
  <QueryClientProvider client={queryClient}>
    <NoticeContextProvider>
      <AppContextProvider>
        <CartContextProvider>
          <Component {...pageProps} />
        </CartContextProvider>
      </AppContextProvider>
    </NoticeContextProvider>
  </QueryClientProvider>
);

export default appWithTranslation(MyApp);
