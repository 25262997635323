export const API_URLS = {
  AUTH: {
    RECOVERY_VERIFY: '/auth/recoveryVerify',
    RECOVERY: '/auth/recovery',
    SIGN_IN: '/auth/sign-in',
    SIGN_UP: '/auth/sign-up',
    GOOGLE: '/auth/google',
    VERIFY: '/auth/verify',
    ME: '/auth/me',
    LOGOUT: '/auth/logout',
    ACCEPT_TERMS: '/auth/terms',
  },
  PROVIDER: {
    PRODUCTS: '/admin/provider/products',
    LIST: '/admin/provider',
  },
  CHECKOUT: {
    CREATE_ORDER: '/checkout',
    SUCCESS: '/checkout/success',
  },
  PRODUCT: {
    LIST: '/product/list',
    LIST_LINK: 'product/region/[:link]',
    REGIONS: '/product/regions',
    GET: '/product',
    LINK: '/product/[:link]',
    POPULAR: '/product/popular',
    SEARCH: '/product/search',
    DISCOUNT: '/product/discount',
  },
  CATEGORY: {
    LIST: '/product/category',
    GET: '/product/category/[:link]',
  },
  USER: {
    SET_CART: '/user/cart',
    DELETE_ACCOUNT: '/user/archive',
    CURRENCY: '/user/currency',
    LANGUAGE: '/user/language',
    CART_CURRENCY: '/user/cart/invalidate',
    REFERRER: '/user/referrer',
  },
  ORDER: {
    List: '/order/list',
  },
  REDIRECT: {
    LIST: '/redirect',
  },
};

export const APP_URLS = {
  PRODUCT: {
    REGION: '/region/[:link]',
  },
  PRIVACY: '/privacy-policy',
  SHIPPING_AND_PAYMENT: '/shipping-and-payment',
  ABOUT_US: '/about-us',
  TERMS: '/terms',
  CHECKOUT: '/checkout',
  LOGIN: '/login',
};

export const GOOGLE_URL = {
  get: (url: string, lang: string) => {
    if (process.env.NEXT_PUBLIC_GOOGLE_URL && process.env.NEXT_PUBLIC_GOOGLE_CSCOPE) {
      return process.env.NEXT_PUBLIC_GOOGLE_URL
        .replace('[url]', `${url}/${lang}`)
        .replace('[scope]', process.env.NEXT_PUBLIC_GOOGLE_CSCOPE);
    }
    return undefined;
  },
};
export const OutsideHookTypes = {
  SEARCH: 'SEARCH',
};

export const AMOUNT_ACTIONS = {
  PLUS: 'PLUS',
  MINUS: 'MINUS',
};

export const PAYPAL_CLIENT_ID = process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID;

export enum ECurrency {
  USD = 'USD',
  UAH = 'UAH',
}

export const CURRENCY = {
  [ECurrency.USD]: { sign: '$', val: ECurrency.USD, title: 'United States Dollar' },
  [ECurrency.UAH]: { sign: '₴', val: ECurrency.UAH, title: 'Ukrainian Hryvnia' },
};

export const LOCALE = {
  en: { flag: '&#127482;&#127480;', title: 'United States (English)' },
  ua: { flag: '&#127482;&#127462;', title: 'Ukrainian (УкраЇнська)' },
  ru: { flag: '&#127479;&#127482;', title: 'Russian (Русский)' },
};

export const CURRENCY_ROUTES = ['/category/[link]', '/region/[link]', '/product/[link]'];

export const EMAILS = {
  support: 'support@hotcarddelivery',
};

export const AVAILABLE_REFERRERS = ['instagram', 'google-merchant', 'pubgcarddelivery', 'google-ads'];
